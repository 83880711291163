import * as CONST from '../../ActionTypes';
import * as util from '../index';

/**
 * 登録
 */
export const update = (accessToken: string, param: object) => (dispatch) => {
  dispatch(util.loading());
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/drivers/me`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(param),
    mode: 'cors',
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(util.requestSuccess(CONST.UPDATE_PROFILE_DRIVER_SUCCESS, null));
        dispatch(util.unLoading());
        const refreshToken = localStorage.getItem('refresh_token_driver');
        // eslint-disable-next-line no-shadow
        const param = {
          refresh_token: refreshToken,
        };
        dispatch(refreshTokenDriver(param));
        return null;
      }
      dispatch(util.requestSuccess(CONST.UPDATE_EMAIL_DRIVER_FAILURE, '入力したデータに誤りがありました。'));

      dispatch(util.unLoading());
      return response.json();
    })
    .catch((error) => {
      dispatch(util.requestFailure(CONST.UPDATE_PROFILE_DRIVER_FAILURE, error));
      dispatch(util.unLoading());
    });
};

/**
 * リフレッシュトークンでログイン
 */
export const refreshTokenDriver = (param) => (dispatch) => {
  dispatch(util.loading());
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/drivers/refresh_token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(param),
    mode: 'cors',
  })
    .then((response) => response.json())
    .then((payload) => {
      if (payload.access_token && payload.refresh_token) {
        dispatch(util.requestSuccess(CONST.REFRESH_TOKEN_DRIVER_SUCCESS, payload));
        dispatch(util.unLoading());
        // デバイスにリフレッシュトークンを保存
        util.setTokenDriver(payload.refresh_token);
      } else {
        dispatch(util.requestFailure(CONST.REFRESH_TOKEN_DRIVER_FAILURE, null));
        dispatch(util.unLoading());
      }
    })
    .catch((error) => {
      dispatch(util.requestFailure(CONST.REFRESH_TOKEN_DRIVER_FAILURE, error));
      dispatch(util.unLoading());
    });
};
export const resetMessageUpdate = () => (dispatch) => {
  dispatch(util.requestSuccess(CONST.CLEAR_MESSAGE_DRIVER, ''));
};

export const updateEmail = (accessToken: string, param: object) => (dispatch) => {
  dispatch(util.loading());
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/drivers/send_update_email_mail`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(param),
    mode: 'cors',
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(util.requestSuccess(CONST.UPDATE_EMAIL_DRIVER_SUCCESS, null));
        dispatch(util.unLoading());
        return null;
      }
      if (response.status === 400) {
        dispatch(util.requestSuccess(CONST.UPDATE_EMAIL_DRIVER_FAILURE, 'すでに登録されているメールアドレスです'));
        dispatch(util.unLoading());
        return null;
      }
      return response.json();
    })
    .catch((error) => {
      dispatch(util.requestFailure(CONST.UPDATE_EMAIL_DRIVER_FAILURE, error.detail));
      dispatch(util.unLoading());
    });
};
export const verifyToken = (param: object) => (dispatch) => {
  dispatch(util.loading());
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/drivers/update_email`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(param),
    mode: 'cors',
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(util.requestSuccess(CONST.VERIFY_TOKEN_DRIVER_SUCCESS, null));
        dispatch(util.unLoading());
        return null;
      }
      if (response.status === 404) {
        dispatch(util.requestSuccess(CONST.VERIFY_TOKEN_DRIVER_FAILURE, 'トークンの有効期限が切れています'));
        dispatch(util.unLoading());
        return null;
      }
      return response.json();
    })
    .catch((error) => {
      dispatch(util.requestFailure(CONST.VERIFY_TOKEN_DRIVER_FAILURE, error.detail));
      dispatch(util.unLoading());
    });
};
export const deleteMemberShipDriver = (accessToken) => (dispatch) => {
  dispatch(util.loading());
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/drivers/withdraw`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: null,
    mode: 'cors',
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(util.requestSuccess(CONST.VERIFY_TOKEN_CUSTOMER_SUCCESS, null));
        dispatch(util.unLoading());
        return null;
      }
      if (response.status === 404) {
        dispatch(util.requestSuccess(CONST.VERIFY_TOKEN_CUSTOMER_FAILURE, 'トークンの有効期限が切れています'));
        dispatch(util.unLoading());
        return null;
      }
      return response.json();
    })
    .catch((error) => {
      dispatch(util.requestFailure(CONST.VERIFY_TOKEN_CUSTOMER_FAILURE, error.detail));
      dispatch(util.unLoading());
    });
};
export const resetMessage = () => (dispatch) => {
  dispatch(util.requestSuccess(CONST.VERIFY_TOKEN_DRIVER_FAILURE, ''));
};

export const getListNotification = (accessToken) => (dispatch) => {
  dispatch(util.loading());
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/public/bulletin_board_top?limit=3&offset=0&sort=created_at.desc&role=driver`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    mode: 'cors',
  })
    .then((response) => response.json())
    .then((payload) => {
      dispatch(util.actionType(CONST.GET_LIST_NOTIFICATIONS_SUCCESS, payload));
      dispatch(util.unLoading());
      return payload;
    })
    .catch((error) => {
      dispatch(util.actionType(CONST.GET_LIST_NOTIFICATIONS_FAILURE, error));
      dispatch(util.unLoading());
      return [];
    });
};

export const getListMember = (accessToken, param) => (dispatch) => {
  dispatch(util.loading());
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/drivers/list_members?limit=${param.limit}&offset=${param.offset}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    mode: 'cors',
  })
    .then((response) => response.json())
    .then((payload) => {
      dispatch(util.actionType(CONST.GET_LIST_DRIVER_MEMBER_SUCCESS, payload));
      dispatch(util.unLoading());
    })
    .catch((error) => {
      dispatch(util.actionType(CONST.GET_LIST_DRIVER_MEMBER_FAILURE, error));
      dispatch(util.unLoading());
    });
};
export const disableMember = (accessToken, id) => (dispatch) => {
  dispatch(util.loading());
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/drivers/disable_members/${id}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    mode: 'cors',
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(util.actionType(CONST.DISABLE_DRIVER_MEMBER_SUCCESS, null));
        dispatch(util.unLoading());
        return null;
      }
      return response.json();
    })
    .catch((error) => {
      dispatch(util.actionType(CONST.GET_LIST_DRIVER_MEMBER_FAILURE, error));
      dispatch(util.unLoading());
    });
};
